import { apiURL } from '@/main'
import { app } from '@/main'
import { showError } from '@/services/axios'
import { defineStore } from 'pinia'
export const useFarmAccessStatusesStore = defineStore('farmAccessStatusesStore', {
  state: () => ({
    farmAccessStatuses: [] as any[],
    limitedFarmAccessStatuses: [] as any[]
  }),
  actions: {
    async fetchFarmAccessStatuses() {
      if (this.farmAccessStatuses.length == 0) {
        try {
          const result = await app.axios.get(apiURL + '/farmAccessStatuses')
          this.farmAccessStatuses = result.data
        } catch (e) {
          showError(e)
        }
      }
    },
    async fetchLimitedFarmAccessStatuses() {
      if (this.limitedFarmAccessStatuses.length == 0) {
        try {
          const result = await app.axios.get(apiURL + '/farmAccessStatuses')
          this.limitedFarmAccessStatuses = result.data.splice(0, result.data.length - 2)
        } catch (e) {
          showError(e)
        }
      }
    }
  }
})
